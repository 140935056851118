// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import { post, put } from '../api'
import { openAlert } from './alert'
import { removeToken } from '../utility/Utils'

// const config = useJwt.jwtConfig

const LOGIN_MESSAGE = [
  "Logged in successfully.",
  "Please enter a valid email address or password!",
  "Something is wrong while login, please try again."
]

const FORGOT_PASSWORD_MESSSAGE = [
  "Password reset successfully.",
  "Something is wrong while change password, please try again."
]

const CHANGE_PASSWORD_MESSSAGE = [
  "Password reset successfully.",
  "Something is wrong while change password, please try again."
]

const RESET_MESSAGE_MESSAGE = [
  "Successfully send link to your email address.",
  "Please enter a valid email address.",
  "Something is wrong while Reset Password, please try again."
]

const commonReduxType = {
  loading: false,
  data: {},
  error: null,
  is_success: false
}


// ========== INITIALSTATE ==========
const initialState = {
  login: commonReduxType,
  reset_password: commonReduxType,
  forgot_password: commonReduxType,
  change_password: commonReduxType,
}


// ========== API CALL LOGIN ==========
export const login = createAsyncThunk(
  "login",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/account/login/", "", data)
      dispatch(openAlert({ message: LOGIN_MESSAGE[0], severity: "success" }))
      return response
    } catch (error) {
      const message =
      error?.response?.status === 401 ? LOGIN_MESSAGE[1] : LOGIN_MESSAGE[2]
     

      if (error?.response?.status !== 401) {
        dispatch(
          openAlert({
            message,
            severity: "danger",
            title: "Error"
          })
        )
      }
      throw rejectWithValue(error.response ? message : error.message)
    }
  }
)


// ========== API CALL RESET PASSWORD ==========
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/account/reset-password/", "", data)
      dispatch(
        openAlert({ message: RESET_MESSAGE_MESSAGE[0], severity: "success" })
      )
      return response
    } catch (error) {
      const message = [404].includes(error?.response?.status) ? RESET_MESSAGE_MESSAGE[1] : RESET_MESSAGE_MESSAGE[2]
      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error"
        })
      )
      throw rejectWithValue(error.response ? message : error.message)
    }
  }
)


// ========== API CALL FORGOT PASSWORD ==========
export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ formData, slug }, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/account/forgot-password/${slug}`,
        "",
        formData
      )
      dispatch(
        openAlert({ message: FORGOT_PASSWORD_MESSSAGE[0], severity: "success" })
      )
      return response
    } catch (error) {
      const message = [404].includes(error?.response?.status) ? "User Does Not Exist" : error?.response?.data?.error || FORGOT_PASSWORD_MESSSAGE[1]
      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error"
        })
      )
      throw rejectWithValue(error.response ? message : error.message)
    }
  }
)

// ========== API CALL FORGOT PASSWORD ==========
export const changePassword = createAsyncThunk(
  "changePassword",
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(
        `/adminpanel/changepassword/`,
        "",
        formData
      )
      dispatch(
        openAlert({ message: CHANGE_PASSWORD_MESSSAGE[0], severity: "success" })
      )
      return response
    } catch (error) {
      const message = [404].includes(error?.response?.status) ? "User Does Not Exist" : error?.response?.data?.error || CHANGE_PASSWORD_MESSSAGE[1]
      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error"
        })
      )
      throw rejectWithValue(error.response ? message : error.message)
    }
  }
)


export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    handleLogout: (state) => {
      state.login = {
        data: {},
        loading: false,
        is_success: false,
        error: null
      }
      removeToken()
      window.location.replace("/login")
    },

    emptyForgotPassword: (state) => {
      state.forgot_password = {
        ...state.forgot_password,
        is_success: false,
        loading: false,
        error: null,
        data: {}
      }
    },
    emptyChangepassword: (state) => {
      state.change_password = {
        ...state.change_password,
        is_success: false,
        loading: false,
        error: null,
        data: {}
      }
    },

    emptyResetPassword: (state) => {
      state.reset_password = {
        ...state.reset_password,
        is_success: false,
        loading: false,
        error: null,
        data: {}
      }
    }
  },
  extraReducers: (builder) => {
      //  =================================== LOGIN CASES ===================================
      builder.addCase(login.pending, (state) => {
        state.login = {
          ...state.login,
          loading: true,
          is_success: false,
          error: null
        }
      })
      builder.addCase(login.fulfilled, (state, action) => {
        state.login = {
          ...state.login,
          is_success: true,
          loading: false,
          data: action.payload
        }
      })
      builder.addCase(login.rejected, (state, action) => {
        state.login = {
          ...state.login,
          is_success: false,
          loading: false,
          error: action.payload
        }
      })
      //  =================================== LOGIN CASES ===================================


      //  =================================== FORGOT PASSWORD CASES ===================================
      builder.addCase(forgotPassword.pending, (state) => {
        state.forgot_password = {
          ...state.forgot_password,
          is_success: false,
          loading: true,
          error: null
        }
      })
      builder.addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgot_password = {
          ...state.forgot_password,
          is_success: true,
          loading: false,
          data: action.payload
        }
      })
      builder.addCase(forgotPassword.rejected, (state, action) => {
        state.forgot_password = {
          ...state.forgot_password,
          is_success: false,
          loading: false,
          error: action.payload
        }
      })
      //  =================================== FORGOT PASSWORD CASES ===================================


      //  =================================== CHANGE PASSWORD CASES ===================================
      builder.addCase(changePassword.pending, (state) => {
        state.change_password = {
          ...state.change_password,
          is_success: false,
          loading: true,
          error: null
        }
      })
      builder.addCase(changePassword.fulfilled, (state, action) => {
        state.change_password = {
          ...state.change_password,
          is_success: true,
          loading: false,
          data: action.payload
        }
      })
      builder.addCase(changePassword.rejected, (state, action) => {
        state.change_password = {
          ...state.change_password,
          is_success: false,
          loading: false,
          error: action.payload
        }
      })
      //  =================================== CHANGE PASSWORD CASES ===================================


      //  =================================== RESET PASSWORD CASES ===================================
      builder.addCase(resetPassword.pending, (state) => {
        state.reset_password = {
          ...state.reset_password,
          is_success: false,
          loading: true,
          error: null
        }
      })
      builder.addCase(resetPassword.fulfilled, (state, action) => {
        state.reset_password = {
          ...state.reset_password,
          is_success: true,
          loading: false,
          data: action.payload
        }
      })
      builder.addCase(resetPassword.rejected, (state, action) => {
        state.reset_password = {
          ...state.reset_password,
          is_success: false,
          loading: false,
          error: action.payload
        }
      })
      //  =================================== RESET PASSWORD CASES ===================================

  }
})

export const { handleLogin, handleLogout, emptyForgotPassword, emptyResetPassword, emptyChangepassword } = authSlice.actions

export default authSlice.reducer
