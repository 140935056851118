// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import alert from "./alert";
import profile from "./profile";
import dashboardCount from "./dashboardCount";
import userList from "./userList";

// import profile from './profile'
import dashboard from "./dashboardSlice";
import datasource from "./dataSourceSlice";

const rootReducer = {
  auth,
  profile,
  alert,
  dashboard,
  datasource,

  //  ================ APPLICATION STORE AND STATE  ================
  navbar,
  layout,
  dashboardCount,
  userList,
};

export default rootReducer;
