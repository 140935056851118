import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { get } from "../api"

const commonReduxType = {
  loading: false,
  data: {},
  error: null,
  is_success: false
}

// ========== INITIALSTATE ==========
const initialState = {
  user: commonReduxType
}

// ========== API CALL LOGIN ==========
export const profile = createAsyncThunk(
  "profile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await get("/account/profile/", "")
      return response
    } catch (error) {
      
      const message = "Error occure while fetch profile"

      throw rejectWithValue(error.response ? message : error.message)
    }
  }
)

// ========== PROFILE REDUCER ==========
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  =================================== PROFILE CASES ===================================
    builder.addCase(profile.pending, (state) => {
      state.user = {
        ...state.user,
        loading: true,
        is_success: false,
        error: null
      }
    })
    builder.addCase(profile.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        loading: false,
        is_success: true,
        data: action.payload
      }
    })
    builder.addCase(profile.rejected, (state, action) => {
      state.user = {
        ...state.user,
        loading: false,
        is_success: false,
        error: action.error.message
      }
    })
    //  =================================== PROFILE CASES ===================================
  }
})

export default profileSlice.reducer
