// ** React Imports
import { Fragment } from 'react'

// ** Third Party Components
import * as Icon from 'react-feather'
// import classnames from 'classnames'

// ** Custom Component
import Autocomplete from '@components/autocomplete'

// ** Reactstrap Imports
import {
  NavItem,
  NavLink
} from 'reactstrap'

// ** Store & Actions
// import { useDispatch } from 'react-redux'
// import { getBookmarks  } from '@store/navbar'

const NavbarBookmarks = props => {
  // ** Props
  const { setMenuVisibility } = props

  // ** State
  // const [value, setValue] = useState('')
  // const [openSearch, setOpenSearch] = useState(false)

  // ** Store Vars
  // const dispatch = useDispatch()
  // const store = useSelector(state => state.navbar)

  // ** ComponentDidMount
  // useEffect(() => {
  //   dispatch(getBookmarks())
  // }, [])
 

  return (
    <Fragment>
      <ul className='navbar-nav d-xl-none'>
        <NavItem className='mobile-menu me-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
            <Icon.Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
    </Fragment>
  )
}

export default NavbarBookmarks
