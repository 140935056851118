// alertSlice.js
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isOpen: false,
  message: "",
  severity: "info" // default severity
}

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openAlert(state, action) {
      state.isOpen = true
      state.message = action.payload.message
      state.title = action.payload.title
      state.severity = action.payload.severity || "info"
    },
    closeAlert(state) {
      state.isOpen = false
    }
  }
})

export const { openAlert, closeAlert } = alertSlice.actions
export default alertSlice.reducer
