import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastBody, ToastHeader, Alert } from "reactstrap";
import { closeAlert } from "../redux/alert";
import { CheckCircle, AlertCircle } from "react-feather";

export const AlertSidebar = () => {
  const dispatch = useDispatch();
  const { isOpen, message, 
    severity
    } = useSelector((state) => state.alert);

  const hideAlert = () => {
    dispatch(closeAlert());
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        hideAlert();
      }, 5000);
    }
  }, [dispatch, isOpen]);

  // const severity ="success" || "danger" || "info"

  return (
    <div
      className="position-absolute"
      style={{ zIndex: "99999", top: "20px", right: "50px" }}
    >
      <Alert
        className={"py-0.5 pe-2"}
        color={severity}
        isOpen={isOpen}
        toggle={hideAlert}
        style={{
          ...(severity === "success" ? {
                backgroudColor: "rgb(237, 247, 237) !important",
                color: "rgb(30, 70, 32) !important",
              } : severity === "danger" ? {
                backgroudColor: "rgb(253, 237, 237) !important",
                color: "rgb(95, 33, 32) !important",
              } : {
                backgroudColor: "rgb(229, 246, 253) !important",
                color: "rgb(1, 67, 97) !important",
              }),
        }}
      >
        <div className="alert-body">
          {severity === "success" ? (
            <CheckCircle size={19} />
          ) : (
            <AlertCircle size={19} />
          )}
          <span className="ms-1">{message} </span>
        </div>
      </Alert>
    </div>
  );
};
