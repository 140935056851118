// ** Icons Import
import { Home, Database, User, Lock } from "react-feather"

export default [
  {
    id: "Dashboard",
    title: "Dashboard",
    icon: <Home size={20} />,
    badge: "light-warning",
    navLink: "/dashboard"
  },
  // {
  //   id: "Analalyst",
  //   title: "Analalyst",
  //   icon: <Circle size={20} />,
  //   badge: "light-warning",
  //   navLink: "/Analalyst"
  // },
  {
    id: "Data-Source",
    title: "Data Source",
    icon: <Database size={20} />,
    badge: "light-warning",
    navLink: "/data-source"
  },
  {
    id: "Users",
    title: "Users",
    icon: <User size={20} />,
    badge: "light-warning",
    navLink: "/users"
  },
  // {
  //   id: "Chage-password",
  //   title: "Change Password",
  //   icon: <Lock size={20} />,
  //   badge: "light-warning",
  //   navLink: "/change-password"
  // }
]