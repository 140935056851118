// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import { dlt, get } from "../api";
import { openAlert } from "./alert";

const DATA_SOURCE_MESSAGE = [
  "get Data source list successfully.",
  "Something is wrong while get user data, please try again.",
];

const DELETE_DATA_FILE_MESSAGE = [
  "File deleted successfully.",
  "Error occure while deleting data, please try again.",
];

const commonReduxType = {
  loading: false,
  data: {},
  error: null,
  is_success: false,
};

// ========== INITIALSTATE ==========
const initialState = {
  data_source_list: { ...commonReduxType, data: [] },
  delete_data_source_file: { ...commonReduxType },
};

// ========== API CALL GET DATA SOURCE LIST ==========
export const dataSourceList = createAsyncThunk(
  "DataSourceList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get(
        `/adminpanel/data/?page=${data?.page}&page_size=${data.page_size}&search=${data.search}`,
        ""
      );

      return response;
    } catch (error) {
      const message = DATA_SOURCE_MESSAGE[1];

      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error",
        })
      );

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL GET DELETE DATA SOURCE FILE ==========
export const deleteDataSourceFile = createAsyncThunk(
  "deleteDataSourceFile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(
        `/adminpanel/datasourcedelete/${data?.file_id}/`,
        ""
      );
      dispatch(
        openAlert({ message: DELETE_DATA_FILE_MESSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = DELETE_DATA_FILE_MESSAGE[1];

      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error",
        })
      );

      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

export const dataSourceSlice = createSlice({
  name: "dataSourceSlice",
  initialState,
  reducers: {
    emptyDeleteDataSourceFile: (state) => {
      state.delete_file = {
        ...state.delete_file,
        is_success: false,
        loading: false,
        data: {},
      };
    },
  },
  extraReducers: (builder) => {
    //  =================================== DATA SOURCE LIST CASES ===================================
    builder.addCase(dataSourceList.pending, (state) => {
      state.data_source_list = {
        ...state.data_source_list,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(dataSourceList.fulfilled, (state, action) => {
      state.data_source_list = {
        ...state.data_source_list,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(dataSourceList.rejected, (state, action) => {
      state.data_source_list = {
        ...state.data_source_list,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DATA SOURCE LIST CASES ===================================

    //  =================================== DELETE DATA SOURCE LIST CASES ===================================
    builder.addCase(deleteDataSourceFile.pending, (state) => {
      state.delete_data_source_file = {
        ...state.delete_data_source_file,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(deleteDataSourceFile.fulfilled, (state, action) => {
      state.delete_data_source_file = {
        ...state.delete_data_source_file,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteDataSourceFile.rejected, (state, action) => {
      state.delete_data_source_file = {
        ...state.delete_data_source_file,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== DELETE DATA SOURCE LIST CASES ===================================
  },
});
export const { emptyDeleteDataSourceFile } = dataSourceSlice.actions;
export default dataSourceSlice.reducer;
