// ** React Imports
import { Navigate } from 'react-router-dom'
import {  Suspense } from 'react'

// ** Context Imports
import { getToken } from '../../../utility/Utils'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const user = JSON.parse(localStorage.getItem('userData'))
  const token = getToken()
 
  if (route) {
    // let action = null
    // let resource = null
    // let restrictedRoute = false

    if (route.meta) {
      // action = route.meta.action
      // resource = route.meta.resource
      // restrictedRoute = route.meta.restricted
    }

    // ============================== PRIVATE ROUTES =============================
    
    if (!token) {
      return <Navigate to='/login' />
    }
    return <Suspense fallback={null}>{children}</Suspense>
    // if (user && restrictedRoute) {
    //   return <Navigate to='/' />
    // }
    // if (user && restrictedRoute && user.role === 'client') {
    //   return <Navigate to='/access-control' />
    // }
    // if (user && !ability.can(action || 'read', resource)) {
    //   return <Navigate to='/misc/not-authorized' replace />
    // }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
