import { lazy } from 'react'

const DashboardAnalytics = lazy(() => import('../../views/dashboard'))
const UsersList = lazy(() => import('../../views/users/list'))
const Profile = lazy(() => import('../../views/profile'))
const DataSource = lazy(() => import('../../views/data-source/list'))
const ChangePassword = lazy(() => import('../../views/change-password'))

const CustomDashboardRoutes = [
  {
    path: '/dashboard',
    element: <DashboardAnalytics />
  },
  {
    path: '/users',
    element: <UsersList />
  },
  {
    path: '/data-source',
    element: <DataSource />
  },
  {
    path: '/profile',
    element: <Profile />
  },
  {
    path: '/change-password',
    element: <ChangePassword />,
    // meta:{
    //   // layout: 'blank',
    // }
  }
]

export default CustomDashboardRoutes
