// ** React Imports
import { Link } from "react-router-dom"
// import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from "@components/avatar"

// ** Utils
// import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from "react-redux"
import { handleLogout } from "@store/authentication"

// ** Third Party Components
import { Power, Lock } from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/avatar.png"
import { getUserData } from "@utils"

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  // GET USER DATA
  const userData = getUserData()

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{userData["first_name"]}</span>
          <span className="user-status">
            {userData?.role ? "Super Admin" : ""}
          </span>
        </div>
        <Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/change-password">
          <Lock size={14} className="me-75" />
          <span className="align-middle">Change Password</span>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => dispatch(handleLogout())}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
