import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { get } from "../api"

// Initial state
const initialState = {
  count: null,
  loading: false,
  error: null
}

// Async thunk for the API call
export const dashboardCount = createAsyncThunk(
  "dashboard/dashboardCount",
  async (_, { rejectWithValue }) => {
    try {
      // Replace this URL with your actual API endpoint
      const response = await get("/adminpanel/dashboard/", "")      
      return response
    } catch (error) {
      // Return a custom error message
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch dashboard data"
      )
    }
  }
)

// Slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboardCount.pending, (state) => {
        state.loading = true
        state.error = null
      })
      builder.addCase(dashboardCount.fulfilled, (state, action) => {
        state.loading = false
        state.count = action.payload
      })
      builder.addCase(dashboardCount.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload || "Something went wrong"
      })
  }
})

// Export the reducer
export default dashboardSlice.reducer
