import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { dlt, get, put, post } from "../api"
import { openAlert } from "./alert"

// Initial state
const initialState = {
  users: null,
  subusers: null,
  loading: false,
  subuserLoading: false,
  error: null,
  subuserError: null,

  addedUser: null,
  addNewUserLoading: false,
  addNewUserError: null,

  updatedUser: null,
  updatedUserLoading: false,
  updatedUserError: null,

  updatedSubUser: null,
  updatedSubUserLoading: false,
  updatedSubUserError: null,

  restrictUsers: null,
  restrictUsersLoading: false,
  restrictUsersError: null,

  deleteUser: null,
  deleteUserLoading: false,
  deleteUserError: null,

  extendUserFreeTrial: null,
  extendUserFreeTrialLoading: false,
  extendUserFreeTrialError: null,
}

export const userList = createAsyncThunk(
  "userList",
  async (params, { rejectWithValue }) => {    
    try {
      const response = await get(`/adminpanel/userlist?page=${params.page}&page_size=${params.page_size}&search=${params.search}&plan_details=${params.plan}&is_active=${params.is_active}`, "")    
      return response
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user list data"
      )
    }
  }
)

// Async thunk for fetching subusers
export const subuserList = createAsyncThunk(
  "subuserList",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await get(`/adminpanel/subuserlist/admin/${params.id}/subusers?page=${params.page}&page_size=${params.page_size}`, "")
      return response
    } catch (error) {
      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch subuser list data"
      )
    }
  }
)

export const addNewUser = createAsyncThunk(
  "addNewUser",
  async (userData, { dispatch, rejectWithValue }) => {    
    try {
      const response = await post(`/adminpanel/adminadduser/`, "", userData);
      dispatch(
        openAlert({
          message: "Updated user details",
          severity: "success",
        })
      )
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message:"Error while updating user details",
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ id, userData }, { dispatch, rejectWithValue }) => {    
    try {
      const response = await put(`/adminpanel/adminuseredit/${id}/`, "", userData);
      dispatch(
        openAlert({
          message: "Updated user details",
          severity: "success",
        })
      )
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message:"Error while updating user details",
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const updateSubUser = createAsyncThunk(
  "updateSubUser",
  async ({ id, userData }, { dispatch, rejectWithValue }) => {      
    try {
      const response = await put(`/adminpanel/adminuseredit/${id}/`, "", userData);
      dispatch(
        openAlert({
          message:"Updated sub-user details",
          severity: "success",
          title: "Success"
        })
      )
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error wgile updating sub-user details",
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ id }, { dispatch, rejectWithValue }) => {      
    try {
      const response = await dlt(`/adminpanel/adminuserdelete/${id}/`, "" ,'');
      dispatch(
        openAlert({
          message: "User deleted successfully",
          severity: "success",
          title: "Success"
        })
      )
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error while deleting user",
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete user data"
      );
    }
  }
);

export const restrictUser = createAsyncThunk(
  "restrictUser",
  async ({ id }, { dispatch, rejectWithValue }) => {      
    try {
      const response = await put(`/adminpanel/toggle/${id}/`, "");
      setTimeout(() => {
        if (response?.detail?.includes("Restricted")) {
          dispatch(
            openAlert({
              message: "User restricted successfully",
              severity: "success",
              title: "Success"
            })
          )
        } else {
          dispatch(
            openAlert({
              message: "User activated successfully",
              severity: "success",
              title: "Success"
            })
          )
        }
      }, 1000);
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error",
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);


export const extendUserFreetrial = createAsyncThunk(
  "extendUserFreetrial",
  async ({ id }, { dispatch, rejectWithValue }) => {      
    try {
      const response = await put(`/adminpanel/freetrialextend/${id}/`, "");
      dispatch(
        openAlert({
          message: "User free trial update successfully",
          severity: "success",
          title: "Success"
        })
      )
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error",
          severity: "danger",
          title: "Error"
        })
      )
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

// Slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Cases for userList
      .addCase(userList.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.loading = false
        state.users = action.payload
      })
      .addCase(userList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload || "Something went wrong"
      })
      
      // Cases for subuserList
      .addCase(subuserList.pending, (state) => {
        state.subuserLoading = true
        state.subuserError = null
      })
      .addCase(subuserList.fulfilled, (state, action) => {
        state.subuserLoading = false
        state.subusers = action.payload
      })
      .addCase(subuserList.rejected, (state, action) => {
        state.subuserLoading = false
        state.subuserError = action.payload || "Something went wrong"
      })

      // Cases to update user
      .addCase(addNewUser.pending, (state) => {
        state.addNewUserLoading = true
        state.addNewUserError = null
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.addNewUserLoading = false
        state.addedUser = action.payload
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.addNewUserLoading = false
        state.addNewUserError = action.payload || "Something went wrong"
      })

      // Cases to update user
      .addCase(updateUser.pending, (state) => {
        state.updatedUserLoading = true
        state.updatedUserError = null
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updatedUserLoading = false
        state.updatedUser = action.payload
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updatedUserLoading = false
        state.updatedUserError = action.payload || "Something went wrong"
      })

      // Cases to update sub user
      .addCase(updateSubUser.pending, (state) => {
        state.updatedSubUserLoading = true
        state.updatedSubUserError = null
      })
      .addCase(updateSubUser.fulfilled, (state, action) => {
        state.updatedSubUserLoading = false
        state.updatedSubUser = action.payload
      })
      .addCase(updateSubUser.rejected, (state, action) => {
        state.updatedSubUserLoading = false
        state.updatedSubUserError = action.payload || "Something went wrong"
      })


      // Cases to Restrict user 
      .addCase(restrictUser.pending, (state) => {
        state.restrictUsersLoading = true
        state.restrictUsersError = null
      })
      .addCase(restrictUser.fulfilled, (state, action) => {
        state.restrictUsersLoading = false
        state.restrictUsers = action.payload
      })
      .addCase(restrictUser.rejected, (state, action) => {
        state.restrictUsersLoading = false
        state.restrictUsersError = action.payload || "Something went wrong"
      })


      // Cases to DELETE user 
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserLoading = true
        state.deleteUserError = null
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUserLoading = false
        state.deleteUser = action.payload
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserLoading = false
        state.deleteUserError = action.payload || "Something went wrong"
      })


      // Cases to EXTEND user FREE TRIAL 
      .addCase(extendUserFreetrial.pending, (state) => {
        state.extendUserFreeTrialLoading = true
        state.extendUserFreeTrialError = null
      })
      .addCase(extendUserFreetrial.fulfilled, (state, action) => {
        state.extendUserFreeTrialLoading = false
        state.extendUserFreeTrial = action.payload
      })
      .addCase(extendUserFreetrial.rejected, (state, action) => {
        state.extendUserFreeTrialLoading = false
        state.extendUserFreeTrialError = action.payload || "Something went wrong"
      })
  }
})

// Export the reducer
export default dashboardSlice.reducer
