// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** UseJWT import to get config
import { get } from "../api"
import { openAlert } from "./alert"


const DASHBOARD_MESSAGE = [
  "Dashboard get successfully.",
  "Something is wrong while get dashboard data, please try again."
]

const commonReduxType = {
  loading: false,
  data: {},
  error: null,
  is_success: false
}

// ========== INITIALSTATE ==========
const initialState = {
  analyst: commonReduxType
}

// ========== API CALL DASHBOARD ==========
export const dashboardAnalyst = createAsyncThunk(
  "dashboard",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await get("/adminpanel/dashboard/", "")
      //   dispatch(openAlert({ message: DASHBOARD_MESSAGE[0], severity: "success" }))
      return response
    } catch (error) {
      const message = DASHBOARD_MESSAGE[1]

      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error"
        })
      )

      throw rejectWithValue(error.response ? message : error.message)
    }
  }
)

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  =================================== DASHBOARD ANALYST CASES ===================================
    builder.addCase(dashboardAnalyst.pending, (state) => {
      state.analyst = {
        ...state.analyst,
        loading: true,
        is_success: false,
        error: null
      }
    })
    builder.addCase(dashboardAnalyst.fulfilled, (state, action) => {
      state.analyst = {
        ...state.analyst,
        is_success: true,
        loading: false,
        data: action.payload
      }
    })
    builder.addCase(dashboardAnalyst.rejected, (state, action) => {
      state.analyst = {
        ...state.analyst,
        is_success: false,
        loading: false,
        error: action.payload
      }
    })
    //  =================================== DASHBOARD ANALYST CASES ===================================
  }
})

export default dashboardSlice.reducer
